// extracted by mini-css-extract-plugin
export var portfolioProject = "portfolio-project-page-module--portfolioProject--1dhrQ";
export var projectBanner = "portfolio-project-page-module--project-banner--rsHGy";
export var projectBannerContainer = "portfolio-project-page-module--project-banner-container--ICO2V";
export var projectBanner__title = "portfolio-project-page-module--project-banner__title--1jVUw";
export var projectBanner__description = "portfolio-project-page-module--project-banner__description--1shFU";
export var projectBanner__image = "portfolio-project-page-module--project-banner__image--2c128";
export var projectBanner__scrollIncentive = "portfolio-project-page-module--project-banner__scroll-incentive--3fPWS";
export var projectBanner__scrollIncentive__icon = "portfolio-project-page-module--project-banner__scroll-incentive__icon--2s3uP";
export var projectBanner__scrollIncentive__text = "portfolio-project-page-module--project-banner__scroll-incentive__text--DFCJ4";
export var projectInformations = "portfolio-project-page-module--project-informations--jL53y";
export var projectVisualisations = "portfolio-project-page-module--project-visualisations--sE71O";
export var projectVisualisations__header = "portfolio-project-page-module--project-visualisations__header--3LqSF";
export var projectVisualisations__list = "portfolio-project-page-module--project-visualisations__list--3QYHr";
export var projectVisualisations__listContainer = "portfolio-project-page-module--project-visualisations__list-container--YEssu";
export var projectVisualisations__image = "portfolio-project-page-module--project-visualisations__image--1aW_E";
export var projectVisualisations__imageVertical = "portfolio-project-page-module--project-visualisations__image--vertical--18Pld";
export var projectVisualisations__arrow = "portfolio-project-page-module--project-visualisations__arrow--2dwDm";
export var projectVisualisations__arrowWrapper = "portfolio-project-page-module--project-visualisations__arrow-wrapper--3Zb1c";
export var projectSlider__wrapper = "portfolio-project-page-module--project-slider__wrapper--g0Tnv";
export var projectSlider__header = "portfolio-project-page-module--project-slider__header--2he2J";
export var projectSlider__buttonWrapper = "portfolio-project-page-module--project-slider__button-wrapper--3hsJe";