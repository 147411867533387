import { Pin2 } from './Pin2'
import { Pin3 } from './Pin3'

export const getPinIcon = (type) => {
  switch (type) {
    case 'type-2':
      return Pin2
    case 'type-3':
      return Pin3
    default:
      return null
  }
}
