// extracted by mini-css-extract-plugin
export var slider = "ProjectSlider-module--slider--22j4T";
export var slider__info = "ProjectSlider-module--slider__info--1Mw-5";
export var slider__title = "ProjectSlider-module--slider__title--18UH9";
export var slider__subtitle = "ProjectSlider-module--slider__subtitle--2Dj6v";
export var slider__itemImage = "ProjectSlider-module--slider__item-image--2KbF8";
export var slider__itemWrapper = "ProjectSlider-module--slider__item-wrapper--2V64x";
export var slider__reduction = "ProjectSlider-module--slider__reduction--1EKIS";
export var slider__wrapper = "ProjectSlider-module--slider__wrapper--1P9J-";
export var slider__right = "ProjectSlider-module--slider__right--1CGgn";
export var slider__left = "ProjectSlider-module--slider__left--3W8vP";