import React from 'react'
import PropTypes from 'prop-types'
import { Spring } from 'react-spring/renderprops'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import * as styles from './ProjectInformation.module.scss'

const ProjectInformation = ({ headerText, information, icon: Icon }) => (
  <VisibilitySensorOnce partialVisibility minTopValue={100}>
    {({ isVisible }) => (
      <div className={styles.projectInformation}>
        <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }} config={{ mass: 5 }}>
          {({ opacity, scale }) => (
            <div style={{ opacity, transform: `scale(${scale})` }} className={styles.projectInformation__iconWrapper}>
              <Icon className={styles.projectInformation__icon} />
            </div>
          )}
        </Spring>
        <Spring delay={200} to={{ opacity: isVisible ? 1 : 0 }}>
          {({ opacity }) => (
            <h3 style={{ opacity }} className={styles.projectInformation__header}>
              {headerText}
            </h3>
          )}
        </Spring>
        <Spring delay={340} to={{ opacity: isVisible ? 1 : 0 }}>
          {({ opacity }) => (
            <div style={{ opacity }} className={styles.projectInformation__information}>
              {information}
            </div>
          )}
        </Spring>
      </div>
    )}
  </VisibilitySensorOnce>
)

ProjectInformation.propTypes = {
  headerText: PropTypes.string.isRequired,
  information: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.func.isRequired,
}

export { ProjectInformation }
