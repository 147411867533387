import React, { useMemo } from 'react'
import { Spring } from 'react-spring/renderprops'
import { easeQuadInOut } from 'd3-ease'
import { graphql } from 'gatsby'

import { routes } from '../URLs'
import { Image } from '../components/Image'
import { VisibilitySensorOnce } from '../components/VisibilitySensorOnce'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { Container } from '../components/Container'
import { ArrowIcon } from '../components/Icons/Arrow'
import { getPinIcon } from '../components/Icons/Pin'
import { Home } from '../components/Icons/Home'
import { Sketch } from '../components/Icons/Sketch'
import { Calendar } from '../components/Icons/Calendar'
import { IndentDescription } from '../components/IndentDescription'
import { ProjectSlider } from '../components/ProjectSlider'
import { ProjectInformation } from '../components/ProjectInformation'
import { UnderlinedHeader } from '../components/UnderlinedHeader'
import { Button } from '../components/Button'
import { Section } from '../components/Section'

import * as styles from './portfolio-project-page.module.scss'
import cx from 'clsx'

const mapProjectEdgeToSliderItem = ({
  node: {
    id,
    fields: { slug },
    frontmatter: { title, city, featuredImage },
  },
}) => ({
  id,
  slug,
  title,
  subTitle: city,
  image: featuredImage,
})

const ProjectVisualisation = ({ imgOrientation, imgSrc, alt }) => (
  <VisibilitySensorOnce partialVisibility minTopValue={100}>
    {({ isVisible }) => (
      <Spring to={{ opacity: isVisible ? 1 : 0 }}>
        {({ opacity }) => (
          <Image
            style={{ opacity }}
            className={cx([
              styles.projectVisualisations__image,
              imgOrientation === 'vertical' && styles.projectVisualisations__imageVertical,
            ])}
            src={imgSrc}
            alt={alt}
            responsive
            dpr="auto"
            width="auto"
            crop="scale"
          />
        )}
      </Spring>
    )}
  </VisibilitySensorOnce>
)

const ProjectVisualisations = ({ listOfListsOfImages }) => {
  const images = useMemo(
    () =>
      listOfListsOfImages.reduce((imagesTotal, listOfImages) => {
        imagesTotal.push(
          ...listOfImages.images.map((src) => ({
            src,
            orientation: listOfImages.orientation,
          }))
        )
        return imagesTotal
      }, []),
    [listOfListsOfImages]
  )

  return (
    <Section dark className={styles.projectVisualisations}>
      <div className={styles.projectVisualisations__header}>
        <UnderlinedHeader underlinePlacement="bottom-center">Wizualizacje</UnderlinedHeader>
      </div>
      <div className={styles.projectVisualisations__listContainer}>
        <div className={styles.projectVisualisations__list}>
          {images &&
            images.map((img, idx) => (
              <ProjectVisualisation
                imgOrientation={img.orientation}
                imgSrc={img.src}
                alt={`wizualizacja-${idx}`}
                key={idx}
              />
            ))}
        </div>
      </div>
    </Section>
  )
}

const Project = ({
  data: {
    markdownRemark: {
      id,
      html,
      frontmatter: {
        featuredImage,
        featuredImageIsDark,
        title,
        descriptionShort,
        kind,
        city,
        area,
        budget,
        listOfListsOfImages,
      },
    },
    allMarkdownRemark: { edges: projectEdges },
  },
}) => {
  const sliderItems = useMemo(
    () => projectEdges.filter(({ node: { id: projectId } }) => projectId !== id).map(mapProjectEdgeToSliderItem),
    [projectEdges]
  )

  return (
    <Layout darkHero={featuredImageIsDark}>
      <Seo title={`Portfolio | ${title}`} description={descriptionShort} image={featuredImage} />
      <div className={styles.portfolioProject}>
        <div className={styles.projectBanner}>
          <Container className={styles.projectBannerContainer}>
            <Spring
              delay={1200}
              config={{ tension: 220, mass: 1.5 }}
              from={{ opacity: 0, translate: 70 }}
              to={{ opacity: 1, translate: 0 }}
            >
              {({ opacity, translate }) => (
                <>
                  <h1
                    style={{ opacity, transform: `translateX(${translate}px)` }}
                    className={styles.projectBanner__title}
                  >
                    {title}
                  </h1>
                  <div style={{ opacity }} className={styles.projectBanner__description}>
                    {descriptionShort}
                  </div>
                </>
              )}
            </Spring>
            <Spring
              delay={1600}
              config={{ easing: easeQuadInOut, duration: 700 }}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              {({ opacity }) => (
                <div style={{ opacity }} className={styles.projectBanner__scrollIncentive}>
                  <ArrowIcon className={styles.projectBanner__scrollIncentive__icon} turn="down" />
                  <span className={styles.projectBanner__scrollIncentive__text}>SCROLL</span>
                </div>
              )}
            </Spring>
          </Container>
          <Image
            className={styles.projectBanner__image}
            src={featuredImage}
            alt={title}
            responsive
            dpr="auto"
            width="auto"
            crop="scale"
          />
        </div>
        <div className={styles.projectInformations}>
          <ProjectInformation icon={getPinIcon('type-3')} headerText="Lokalizacja" information={city} />
          <ProjectInformation icon={Home} headerText="Rodzaj" information={kind} />
          <ProjectInformation
            icon={Sketch}
            headerText="Powierzchnia"
            information={
              <>
                {area} m<sup>2</sup>
              </>
            }
          />
          <ProjectInformation
            icon={Calendar}
            headerText="Budżet"
            information={
              <span>
                {budget} PLN / m<sup>2</sup>
              </span>
            }
          />
        </div>
        <ProjectVisualisations listOfListsOfImages={listOfListsOfImages} />
        <Container>
          <IndentDescription>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </IndentDescription>
        </Container>
        <div className={styles.projectSlider}>
          <UnderlinedHeader className={styles.projectSlider__header} underlinePlacement="bottom-center" theme="dark">
            Zobacz inne projekty
          </UnderlinedHeader>
          <div className={styles.projectSlider__wrapper}>
            <ProjectSlider items={sliderItems} />
          </div>
          <div className={styles.projectSlider__buttonWrapper}>
            <Button seeMore href={routes.portfolio} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Project

export const projectPageQuery = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        featuredImage
        featuredImageIsDark
        listOfListsOfImages {
          orientation
          images
        }
        title
        descriptionShort
        kind
        city
        area
        budget
      }
    }
    allMarkdownRemark(
      limit: 6
      filter: { frontmatter: { templateKey: { eq: "portfolio-project-page" } } }
      sort: { fields: [frontmatter___createDate], order: [DESC] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            featuredImage
            title
            city
          }
        }
      }
    }
  }
`
