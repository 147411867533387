import React from 'react'
import cx from 'clsx'

import * as styles from './Pin.module.scss'

const Pin3 = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.pin, styles.pinType3, primary && styles.pinPrimary, className])}
    viewBox="0 0 595.3 595.3"
    fill="currentColor"
    {...props}
  >
    <path d="M519.5 574.3l-78.5-166c-1.6-3.3-4.3-5.9-7.5-7.2l-39-17.4 67.6-113.4c0.2-0.3 0.3-0.5 0.5-0.8 13.4-26.3 20.3-54.6 20.3-84.3 0-49.9-19.6-96.8-55.1-131.8C392.1 18.3 345-0.7 295 0c-48.5 0.7-94.2 20.2-128.7 54.9 -34.4 34.7-53.6 80.6-53.9 129.1 -0.2 29.7 6.8 59.2 20.3 85.4 0.1 0.3 0.3 0.6 0.5 0.8l67.6 113.4 -39.3 17.5c-3.2 1.4-5.8 4-7.3 7.1L75.8 574.3c-2.6 5.6-1.5 12.2 2.8 16.5 4.3 4.4 10.9 5.7 16.5 3.2l98.3-43.8 98.3 43.8c3.8 1.7 8.1 1.7 11.9 0l98.3-43.8 98.3 43.8c1.9 0.9 4 1.3 6 1.3 3.9 0 7.7-1.5 10.5-4.4C521 586.5 522.1 579.9 519.5 574.3zM158.7 255.7c-11.3-22.2-17-46.3-16.8-71.4 0.5-84.2 69.4-153.7 153.6-154.8 42-0.6 81.7 15.3 111.6 44.9 29.9 29.5 46.4 68.9 46.4 110.9 0 24.8-5.7 48.5-16.8 70.5L310.2 467.5c-3.9 6.5-10.1 7.2-12.6 7.2 -2.5 0-8.8-0.7-12.6-7.2L158.7 255.7zM407.9 520.7c-3.8-1.7-8.1-1.7-11.9 0l-98.3 43.8 -98.3-43.8c-3.8-1.7-8.1-1.7-11.9 0l-68.1 30.3 59.2-125.3 37.5-16.7 43.8 73.4c8 13.5 22.2 21.5 37.8 21.5 15.7 0 29.8-8 37.8-21.5l43.8-73.4 37.5 16.7 59.2 125.3L407.9 520.7z" />
    <path d="M297.6 118.1c-37 0-67 30.1-67 67s30.1 67 67 67c37 0 67-30.1 67-67S334.6 118.1 297.6 118.1zM297.6 222.8c-20.8 0-37.7-16.9-37.7-37.7 0-20.8 16.9-37.7 37.7-37.7 20.8 0 37.7 16.9 37.7 37.7C335.3 205.9 318.4 222.8 297.6 222.8z" />
  </svg>
)

export { Pin3 }
