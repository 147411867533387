import React from 'react'
import cx from 'clsx'

import * as styles from './Calendar.module.scss'

const Calendar = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.calendar, primary && styles.calendarPrimary, className])}
    viewBox="0 0 595.3 595.3"
    fill="currentColor"
    {...props}
  >
    <path d="M580.4 71.2H532.7c-7.8 0-14.2 6.4-14.2 14.2s6.4 14.2 14.2 14.2h33.5v86.3H29.1V99.5H104v25.1c0 7.8 6.4 14.2 14.2 14.2s14.2-6.4 14.2-14.2V40.8c0-7.8-6.4-14.2-14.2-14.2S104 33 104 40.8v30.4H14.9c-7.8 0-14.2 6.4-14.2 14.2v114.7c0 7.8 6.4 14.2 14.2 14.2h551.3v210.2l-79.4 79.4v-59.3h39.7c7.8 0 14.2-6.4 14.2-14.2s-6.4-14.2-14.2-14.2h-53.9c-7.8 0-14.2 6.4-14.2 14.2v93.6H29.1V257.5c0-7.8-6.4-14.2-14.2-14.2 -7.8 0-14.2 6.4-14.2 14.2v280.5c0 7.8 6.4 14.2 14.2 14.2h457.8c3.8 0 7.3-1.5 10-4.2l107.7-107.7c2.7-2.7 4.1-6.2 4.1-10V85.3C594.5 77.5 588.2 71.2 580.4 71.2z" />
    <path d="M170.2 99.5h292.7v25.1c0 7.8 6.4 14.2 14.2 14.2s14.2-6.4 14.2-14.2V40.8c0-7.8-6.4-14.2-14.2-14.2s-14.2 6.4-14.2 14.2v30.4H170.2c-7.8 0-14.2 6.4-14.2 14.2S162.4 99.5 170.2 99.5z" />
  </svg>
)

export { Calendar }
