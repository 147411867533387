import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import SwiperCore, { Autoplay, Lazy, Navigation } from 'swiper'
import { Image } from '../Image'
import { Spring } from 'react-spring/renderprops'
import TransitionLink from 'gatsby-plugin-transition-link'

import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import { withBreakpoints } from '../../hoc/withBreakpoints'
import { ArrowIcon } from '../Icons/Arrow'

import * as styles from './ProjectSlider.module.scss'
import './swiper.overwrite.scss'

SwiperCore.use([Lazy, Navigation, Autoplay])

const sliderPropsByBreakpoint = {
  mobile: {
    isMobile: true,
  },
  tablet: {
    isTablet: true,
  },
  desktop: {
    isDesktop: true,
  },
  fallback: {
    isMobile: false,
    isDesktop: false,
    isTablet: false,
  },
}

const ProjectSlider = withBreakpoints(sliderPropsByBreakpoint, ({ isMobile, isDesktop, isTablet, items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const swiperRef = useRef(null)
  const [isEnd, setEnd] = useState(false)

  const activeItem = items[activeItemIndex]

  const goNext = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }, [swiperRef])

  const goPrev = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }, [swiperRef])

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const { swiper } = swiperRef.current

      if (swiper.isEnd) {
        setEnd(true)
      }

      swiper.on('slideChange', () => {
        setActiveItemIndex(swiper.activeIndex)

        if (swiper.isEnd) {
          setEnd(true)
        } else {
          setEnd(false)
        }
      })
    }
  }, [swiperRef])

  return (
    <div className={styles.slider}>
      <VisibilitySensorOnce partialVisibility minTopValue={100}>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <>
                <div style={{ opacity }} className={styles.slider__wrapper}>
                  <div className={styles.slider__reduction}>
                    <Swiper
                      ref={swiperRef}
                      shouldSwiperUpdate
                      slidesPerView={1}
                      spaceBetween={6}
                      breakpoints={{
                        767: {
                          slidesPerView: 2,
                          spaceBetween: 14,
                        },
                        1200: {
                          slidesPerView: 3,
                          spaceBetween: 24,
                        },
                      }}
                    >
                      {items.map(({ id, slug, title, subTitle, image }) => (
                        <TransitionLink
                          className={styles.slider__item}
                          key={id}
                          exit={{
                            length: 0.6,
                          }}
                          entry={{ delay: 0.4, length: 0.1 }}
                          to={slug}
                        >
                          <div className={styles.slider__itemWrapper}>
                            <Image
                              className={styles.slider__itemImage}
                              src={image}
                              alt={title}
                              responsive
                              dpr="auto"
                              width="auto"
                              crop="scale"
                            />
                          </div>
                          {(isDesktop || isTablet) && (
                            <div className={styles.slider__info}>
                              <h3 className={styles.slider__title}>{title}</h3>
                              <h4 className={styles.slider__subtitle}>{subTitle}</h4>
                            </div>
                          )}
                        </TransitionLink>
                      ))}
                    </Swiper>
                    {swiperRef.current && swiperRef.current.swiper && swiperRef.current.swiper.activeIndex !== 0 && (
                      <div className={styles.slider__left} onClick={goPrev}>
                        <ArrowIcon turn="left" />
                      </div>
                    )}
                    {!isEnd && (
                      <div className={styles.slider__right} onClick={goNext}>
                        <ArrowIcon turn="right" />
                      </div>
                    )}
                  </div>
                </div>
                {isMobile && (
                  <div style={{ opacity }} className={styles.slider__info}>
                    <h3 className={styles.slider__title}>{activeItem.title}</h3>
                    <h4 className={styles.slider__subtitle}>{activeItem.subTitle}</h4>
                  </div>
                )}
              </>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
    </div>
  )
})

ProjectSlider.displayName = 'ProjectSlider'

ProjectSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMobile: PropTypes.bool,
}

export { ProjectSlider }
