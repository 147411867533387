import React from 'react'
import cx from 'clsx'

import * as styles from './Pin.module.scss'

const Pin2 = ({ className, primary, ...props }) => (
  <svg
    className={cx([styles.pin, styles.pinType2, primary && styles.pinPrimary, className])}
    viewBox="0 0 595.255 595.282"
    fill="currentColor"
    {...props}
  >
    <path d="M297.6 0C182.2 0 88.3 93.9 88.3 209.3c0 39 10.8 77 31.2 110l166.1 267.7c3.2 5.1 8.8 8.2 14.8 8.2 0 0 0.1 0 0.1 0 6.1 0 11.7-3.3 14.8-8.5L477.4 316.5c19.3-32.3 29.5-69.4 29.5-107.2C506.9 93.9 413 0 297.6 0zM447.5 298.6L300.3 544.3 149.2 300.9c-17-27.5-26.2-59.2-26.2-91.7 0-96.2 78.5-174.6 174.6-174.6 96.2 0 174.5 78.5 174.5 174.6C472.1 240.8 463.5 271.7 447.5 298.6z" />
    <path d="M297.6 104.6c-57.7 0-104.6 46.9-104.6 104.6 0 57.3 46.2 104.6 104.6 104.6 59.2 0 104.6-47.9 104.6-104.6C402.3 151.6 355.3 104.6 297.6 104.6zM297.6 279.3c-38.7 0-70-31.4-70-70 0-38.5 31.5-70 70-70s69.9 31.5 69.9 70C367.5 247.3 336.9 279.3 297.6 279.3z" />
  </svg>
)

export { Pin2 }
